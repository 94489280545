import React from 'react';
import { Link } from 'gatsby';

export default function footer() {
    return (
        <footer className="footer">
            <div className="footer__copyright">
                <span>© 2020 
                    <Link to="/" target="_blank">TeaNotes.dev</Link>
                </span>
            </div>
        </footer>
    )
}
