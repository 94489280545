import React from 'react'

import Layout from "../../components/layout"
import Footer from "../../components/footer"
import BlogRoll from '../../components/blogroll'

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <React.Fragment>
          <h1>Latest Posts</h1>
            <div className="content">
              <BlogRoll />
            </div>
        </React.Fragment>
        <Footer/>
      </Layout>
    )
  }
}
